import React, { Component } from 'react'
import { connect } from 'react-redux'

class BuildItemBuildMarker1 extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    return (
      <div
        className={
          'build__item build__marker-1 ' +
          (this.props.checkLayerInModel(this.props.part).length > 0
            ? 'active'
            : '')
        }
        onClick={() => {
          this.props.chooseComponent(this.props.part)
        }}
      >
        <div className="build__item-img">
          <img src={this.props.part.preview} />
        </div>
        <div className="build__item-name">{this.props.part.name}</div>
        <div className="build__item-label">{this.props.part.code_title}</div>
      </div>
    )
  }
}
function mapStateToProps(state) {
  return {
    settings: state.settings,
  }
}
export default connect(mapStateToProps)(BuildItemBuildMarker1)
